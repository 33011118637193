import { z } from "zod";

export const membershipTypeSchema = z.enum(["one-time", "recurring"], {
  errorMap: () => ({ message: "Membership type is required" }),
});

export type MembershipType = z.infer<typeof membershipTypeSchema>;

export const membershipBillingPeriodSchema = z.enum(["monthly", "annual"], {
  errorMap: () => ({ message: "Billing period is required" }),
});

export type MembershipBillingPeriod = z.infer<typeof membershipBillingPeriodSchema>;

export const membershipStatusSchema = z.enum(
  ["active", "expired", "cancelled", "payment_failed", "trial", "scheduled_cancellation"],
  {
    errorMap: () => ({ message: "Membership status is required" }),
  },
);

export type MembershipStatus = z.infer<typeof membershipStatusSchema>;

export const membershipSchema = z.object({
  /**
   * The membership id
   */
  id: z.string(),

  /**
   * The user id of owner of the membership.
   */
  userId: z.string(),

  /**
   * The type of membership
   */
  type: membershipTypeSchema,

  /**
   * The title of the membership
   */
  title: z
    .string({
      required_error: "Title is required",
      invalid_type_error: "Title must be a string",
    })
    .min(1, { message: "Title is required" })
    .max(20, { message: "Title must be 20 characters or less" }),

  /**
   * The price of the membership in pence
   */
  price: z.union([
    z.literal(0), // Allow a price of 0 for free content
    z
      .number({
        required_error: "Price is required",
        invalid_type_error: "Price must be a number",
      })
      //TODO: Is this a valid min amount?
      .min(230, { message: "The minimum price for paid content is £2.30" }), // Enforce minimum of 230 for paid content
  ]),

  /**
   * The billing period of the membership
   */
  billingPeriod: membershipBillingPeriodSchema.optional(),

  /**
   * The session usage limit of the membership
   */
  sessionUsageLimit: z.number().optional(),

  /**
   * Trial period in days (if applicable)
   */
  trialPeriodInDays: z.number().optional(),

  /**
   * The status of the membership
   */
  status: membershipStatusSchema,

  /**
   * The date and time the membership starts
   *
   * The date is represented as an ISO 8601 date string.
   */
  startDate: z.string().datetime({ offset: true }).optional(),

  /**
   * The date and time the membership expires
   *
   * The date is represented as an ISO 8601 date string.
   */
  endDate: z.string().datetime({ offset: true }).optional(),

  /**
   * The stripe price id for the membership
   */
  stripePriceId: z.string().optional(),

  /**
   * The date and time the content was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),

  /**
   * The date and time the content was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});

export type Membership = z.infer<typeof membershipSchema>;

export const userMembershipSchema = z.object({
  /**
   * Unique identifier for this user's subscription to the membership
   */
  id: z.string(),

  /**
   * The user who purchased the membership
   */
  userId: z.string(),

  /**
   * The membership owners userId
   */
  membershipOwnerUserId: z.string(),

  /**
   * The membership ID this user subscribed to
   */
  membershipId: z.string(),

  /**
   * The title of the membership (cached from original membership)
   */
  title: z.string(),

  /**
   * The price of the membership in pence (cached from original membership)
   */
  price: z.number(),

  /**
   * The type of membership: one-time or recurring
   */
  type: membershipTypeSchema,

  /**
   * The billing period: monthly or annual (only for recurring)
   */
  billingPeriod: membershipBillingPeriodSchema.optional(),

  /**
   * Whether this membership auto-renews (only for recurring)
   */
  autoRenew: z.boolean().optional(),

  /**
   * The status of the user's membership subscription
   */
  status: membershipStatusSchema,

  /**
   * When the membership starts (may be different from purchase date)
   */
  startDate: z.string().datetime({ offset: true }),

  /**
   * When the membership expires (null for active recurring memberships)
   */
  endDate: z.string().datetime({ offset: true }).optional(),

  /**
   * When the next renewal occurs (only for recurring memberships)
   */
  renewalDate: z.string().datetime({ offset: true }).nullish(),

  /**
   * The session usage limit (if applicable)
   */
  sessionUsageLimit: z.number().optional(),

  /**
   * The number of sessions used (for limited-access memberships)
   */
  usageCount: z.number().default(0),

  /**
   * The date the trial period ends (if applicable)
   */
  trialEndDate: z.string().datetime({ offset: true }).optional(),

  /**
   * The date and time the content was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),

  /**
   * The date and time the content was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});

export type UserMembership = z.infer<typeof userMembershipSchema>;
