import HeaderCard from "@/src/pages/profile/components/card/HeaderCard";
import ProfileNotFound from "@/src/pages/profile/components/error/ProfileNotFound";
import { Outlet, useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import { useGetUserProfile } from "../../../hooks/user";

const ProfileLayout = () => {
  const { username } = useParams();
  const { data: user, error, isLoading } = useGetUserProfile(username as string);

  if (!username || (!user && !isLoading)) {
    return <ProfileNotFound />;
  }
  if (error) {
    return (
      <div className="flex items-center justify-center h-full">
        <p>Error fetching user data</p>
      </div>
    );
  }

  return (
    <div className="bg-transparent w-full h-auto rounded-3xl flex flex-col no-scrollbar pb-8 space-y-4">
      {isLoading ? (
        <Loading className="flex items-center justify-center h-screen" />
      ) : (
        <>
          <HeaderCard user={user} isLoading={isLoading} />
          <Outlet />
        </>
      )}
    </div>
  );
};

export default ProfileLayout;
