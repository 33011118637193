// AuthPromptContext.tsx
import AuthPromptModal from "@/components/dialog/AuthPromptModal";
import { createContext, useState } from "react";

interface AuthPromptContextType {
  openAuthPromptModal: (
    action:
      | "like"
      | "comment"
      | "follow"
      | "report"
      | "profile_fetch_next_page"
      | "buy_tickets"
      | "membership_fetch_next_page",
  ) => void;
}

// eslint-disable-next-line react-refresh/only-export-components
export const AuthPromptContext = createContext<AuthPromptContextType | undefined>(undefined);

const AUTH_PROMPT_MODAL_CONFIG = {
  like: {
    icon: "favorite",
    header: "Sign in to like content",
    subheader: "Create an account to engage with content and show your appreciation.",
  },
  comment: {
    icon: "forum",
    header: "Sign in to join the conversation",
    subheader: "Create an account to participate in discussions and engage with the community.",
  },
  follow: {
    icon: "person_add",
    header: "Sign in to follow",
    subheader: "Create an account to follow and stay updated on this user's activity.",
  },
  report: {
    icon: "flag",
    header: "Sign in for settings",
    subheader: "Create an account to engage more with the content you love.",
  },
  profile_fetch_next_page: {
    icon: "lock",
    header: "Sign in to view more",
    subheader: "Create an account to see more content that you love.",
  },
  buy_tickets: {
    icon: "shopping_cart",
    header: "Sign in to buy tickets",
    subheader: "Create an account to purchase tickets and attend sessions.",
  },
  membership_fetch_next_page: {
    icon: "card_membership",
    header: "Sign in to view more memberships",
    subheader: "Create an account to explore additional membership options.",
  },
};

type Props = {
  children?: React.ReactNode;
};

export const AuthPromptProvider = ({ children }: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState(AUTH_PROMPT_MODAL_CONFIG.like);

  const openAuthPromptModal = (action: keyof typeof AUTH_PROMPT_MODAL_CONFIG) => {
    const config = AUTH_PROMPT_MODAL_CONFIG[action];
    if (config) {
      setModalConfig(config);
      setModalOpen(true);
    }
  };

  return (
    <AuthPromptContext.Provider value={{ openAuthPromptModal }}>
      {children}
      <AuthPromptModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        iconName={modalConfig.icon}
        headerText={modalConfig.header}
        subheaderText={modalConfig.subheader}
      />
    </AuthPromptContext.Provider>
  );
};
