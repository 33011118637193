import { z } from "zod";

export const paymentStatusSchema = z.enum([
  "PENDING",
  "COMPLETED",
  "FAILED",
  "REFUNDED",
  "TRIAL",
  "CANCELLED",
  "SCHEDULED_CANCELLATION",
]);
export type PaymentStatus = z.infer<typeof paymentStatusSchema>;

export const paymentTypeSchema = z.enum(["SESSION_EVENT", "MEMBERSHIP"]);
export type PaymentType = z.infer<typeof paymentTypeSchema>;

export const paymentLogTypeSchema = z.enum([
  "TRIAL_START",
  "PAYMENT",
  "RENEWAL",
  "REFUND",
  "FAILED",
  "CANCELLED",
  "SCHEDULED_CANCELLATION",
]);
export type PaymentLogType = z.infer<typeof paymentLogTypeSchema>;

/**
 * Log entry for payments, tracks each transaction event.
 */
export const paymentLogSchema = z.object({
  /**
   * Unique ID for each log entry.
   */
  id: z.string(),

  /**
   * Timestamp when this transaction happened.
   */
  timestamp: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),

  /**
   * Stripe Invoice ID (if applicable for subscription renewals).
   */
  invoiceId: z.string().optional(),

  /**
   * Stripe charge ID (used for tracking payouts).
   */
  chargeId: z.string().optional(),

  /**
   * Amount charged for this event.
   */
  amount: z.number().optional(),

  /**
   * Stripe fee for this transaction.
   */
  stripeFee: z.number().optional(),

  /**
   * Platform service charge for this transaction.
   */
  platformFee: z.number().optional(),

  /**
   * The amount paid out to the content owner after all fees.
   */
  amountPaidToOwner: z.number().optional(),

  /**
   * The event type (trial start, first payment, renewal, refund).
   */
  eventType: paymentLogTypeSchema,
});
export type PaymentLog = z.infer<typeof paymentLogSchema>;

/**
 * Payment details
 */
export const paymentSchema = z.object({
  /**
   * Payment id.
   */
  id: z.string(),
  /**
   * The user id of the user who made the payment.
   */
  userId: z.string(),
  username: z.string(),

  /**
   * The user id of the content owner.
   */
  contentOwnerUserId: z.string(),
  contentOwnerUsername: z.string(),
  amount: z.number(),

  /**
   * Optional discount amount.
   * If the payment was made with a discount, this will be the amount of the discount.
   * Total discount would be amount - discountAmount
   */
  discountAmount: z.number().optional(),

  /**
   * Optional membership id, if the payment was paid for using a membership.
   */
  membershipId: z.string().optional(),

  /**
   * The type of payment.
   */
  type: paymentTypeSchema,

  /**
   * The id of the content item being paid for.
   *
   * currently the sessionEventId or membershipId
   */
  itemId: z.string().min(1),

  /**
   * The id of the payment intent.
   */
  paymentIntentId: z.string().optional(),

  /**
   * The id of the subscription from Stripe, if the payment was for a subscription.
   */
  subscriptionId: z.string().optional(),

  /**
   * Reason for Refund.
   */
  refundReason: z
    .string()
    .trim()
    .min(5, "Reason must be at least 5 characters")
    .max(500, "Reason is too long")
    .refine((reason) => reason.trim().length > 0, {
      message: "Reason cannot be empty or just spaces",
    })
    .optional(),

  status: paymentStatusSchema,

  /**
   * Logs of payment-related events (trial start, payments, renewals).
   */
  logs: z.array(paymentLogSchema).optional(),

  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
});

export type Payment = z.infer<typeof paymentSchema>;

export const paymentEventSchema = z.object({
  eventId: z.string(),
  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
});

export type PaymentEvent = z.infer<typeof paymentEventSchema>;
