import { useAuth, useAuthPrompt } from "@/hooks/auth";
import { CalendarButton } from "@/src/components/button/CalendarButton";
import { FollowButton } from "@/src/components/button/FollowButton";
import { MessageButton } from "@/src/components/button/MessageButton";
import { User } from "@yoga-app/types";
import { Link, useLocation } from "react-router-dom";
import Icon from "../../../../components/icon/Icon";
import ProfileCoverPicture from "../../../../components/profile/ProfileCoverPicture";
import ProfilePicture from "../../../../components/profile/ProfilePicture";
import { EditProfileButton } from "../button/EditProfileButton";

const styles = getComputedStyle(document.documentElement);
const textPrimary = styles.getPropertyValue("--color-textPrimary");
const textSecondary = styles.getPropertyValue("--color-textSecondary");

interface HeroCardProps {
  user: User & { followerCount?: number; followingCount?: number };
  isLoading: boolean;
}

const HeroCard = ({ user }: HeroCardProps) => {
  const { getUserId, isAuthenticated, isLoading: authLoading } = useAuth();
  const { openAuthPromptModal } = useAuthPrompt();
  const userId = getUserId() as string;
  const location = useLocation();

  const tabs = [
    { name: "Main", path: `/${user.username}`, icon: "home" },
    { name: "Memberships", path: `/${user.username}/memberships`, icon: "loyalty" },
  ];

  return (
    <div className="bg-base-100 rounded-3xl shadow p-2 max-w-2xl mx-auto w-full z-10">
      {/* Cover Photo */}
      <div className="relative">
        <ProfileCoverPicture src={user.profileCoverPic?.["1500x500"]} styles="w-full h-80 md:h-96" />
      </div>

      {/* Bottom Section */}
      <div className="relative flex flex-col items-center">
        {/* Profile Picture */}
        <ProfilePicture src={user.profilePic?.[400]} styles="w-36 h-36 sm:w-48 sm:h-48 -mt-20 sm:-mt-24" />

        {/* Icons - Bottom Section */}
        <div className="absolute top-2 left-0 sm:left-2 z-10">
          <CalendarButton username={user.username} iconSize={30} />
        </div>
        <div className="absolute top-2 right-0 sm:right-2 z-10 flex flex-col sm:flex-row items-center gap-2">
          {userId && userId === user.id && <EditProfileButton iconSize={22} />}
          {userId && userId !== user.id && (
            <>
              <MessageButton userId={user.id} iconSize={24} />
              <FollowButton userId={userId} followingUserId={user.id} iconSize={24} />
            </>
          )}
          {!userId && !isAuthenticated && !authLoading && (
            <button
              className="btn btn-outline text-textPrimary border-primary rounded-md px-4 h-8 min-h-8 flex items-center sm:rounded-lg gap-2 sm:gap-4"
              onClick={() => openAuthPromptModal("follow")}
            >
              <Icon name="person_add" size={24} color="currentColor" />
            </button>
          )}
        </div>

        {/* Username, Bio, and Stats */}
        <div className="text-center py-2 px-4">
          <h2 className="text-2xl font-bold text-textPrimary">{user.displayName?.trim() || user.username}</h2>
          <p className="text-textSecondary">@{user.username}</p>
          <p className="text-textSecondary mt-4 break-words">{user.bio}</p>
        </div>

        {/* Stats */}
        <div className="flex w-full justify-evenly items-center py-4">
          <div className="text-center">
            <div className="text-sm text-gray-500">Followers</div>
            <div className="font-semibold">{user.followerCount ?? "~"}</div>
          </div>
          <div className="text-center">
            <div className="text-sm text-gray-500">Following</div>
            <div className="font-semibold">{user.followingCount ?? "~"}</div>
          </div>
        </div>

        {/* Navigation Tabs */}
        <div className="flex w-full flex-row justify-evenly items-center h-12 border-t border-borderSecondary/30">
          {tabs.map((tab) => {
            const isActive = location.pathname === tab.path;
            return (
              <Link
                key={tab.path}
                to={tab.path}
                className={`flex items-center text-sm transition text-textSecondary ${
                  isActive ? "text-textPrimary font-bold underline" : ""
                }`}
              >
                <Icon name={tab.icon} color={isActive ? textPrimary : textSecondary} size={24} fill={isActive} />
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HeroCard;
