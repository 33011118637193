import Icon from "@/components/icon/Icon";
import { useGetUser } from "@/hooks/user";
import SessionEventDetailModal from "@/src/components/dialog/sessionEventModal/EventDetailModal";
import { useQuerySessionEventsBySessionId } from "@/src/hooks/session";
import { getContentIcon } from "@/src/utils/icon";
import { formatDateRange } from "@/utils/date";
import { Session, SessionEvent } from "@yoga-app/types";
import { useEffect, useMemo, useState } from "react";
import { ParticipantInfo } from "./ParticipantInfo";

interface SessionCardActionBarProps {
  session: Session;
  isOwner: boolean;
  selectedSessionEvent?: SessionEvent;
  onSessionEventChange: (sessionEvent: SessionEvent) => void;
}

const todaysDate = new Date();
todaysDate.setHours(0, 0, 0, 0);

const SessionCardActionBar = ({
  session,
  isOwner,
  selectedSessionEvent,
  onSessionEventChange,
}: SessionCardActionBarProps) => {
  const { data: user } = useGetUser(session.userId);
  const [sessionEventDetailModalOpen, setSessionEventDetailModalOpen] = useState(false);

  // Fetch past event
  const { data: pastData } = useQuerySessionEventsBySessionId(
    session.id,
    todaysDate.toISOString(),
    "PAST",
    !selectedSessionEvent,
    {
      limit: 1,
    },
  );

  // Fetch future event
  const { data: futureData } = useQuerySessionEventsBySessionId(
    session.id,
    todaysDate.toISOString(),
    "FUTURE",
    !selectedSessionEvent,
    {
      limit: 1,
    },
  );

  const pastEvents = useMemo(() => pastData?.pages.flatMap((page) => page.items) || [], [pastData]);
  const futureEvents = useMemo(() => futureData?.pages.flatMap((page) => page.items) || [], [futureData]);

  useEffect(() => {
    if (!selectedSessionEvent && (futureEvents.length || pastEvents.length)) {
      const nextUpcomingEvent = futureEvents[0];
      const defaultEvent = nextUpcomingEvent || pastEvents[0];

      if (defaultEvent) {
        onSessionEventChange(defaultEvent);
      }
    }
  }, [futureEvents, pastEvents, selectedSessionEvent, onSessionEventChange]);

  return (
    <>
      <div className="w-full bg-neutral/85 flex flex-row items-center text-base-100 rounded-xl px-2 py-2 justify-between">
        {/* Left Side */}

        <div className="flex flex-row items-center gap-1 overflow-hidden overflow-ellipsis text-nowrap">
          <Icon name={getContentIcon(session.type)} color="inherit" size={24} className="mb-0.5" />
          <h3 className="text-lg tracking-tight font-semibold truncate mr-1">{session?.title}</h3>

          {/* Date time */}
          {selectedSessionEvent && (
            <div className="flex items-center gap-1 font-semibold tracking-tighter text-sm">
              <Icon name="calendar_month" color="inherit" size={24} className="mb-0.5" />
              <span>
                {formatDateRange(selectedSessionEvent?.startDateTime, selectedSessionEvent?.endDateTime, {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </span>
            </div>
          )}

          {session.locationType === "in-person" && (
            <div className="flex items-center font-semibold tracking-tighter text-sm">
              <Icon name="location_on" color="inherit" size={24} className="mb-0.5" />
              <span>{session.city}</span>
            </div>
          )}
          {session.locationType === "online" && (
            <div className="flex items-center font-semibold tracking-tighter text-sm">
              <Icon name="computer" color="inherit" size={24} className="mb-0.5" />
              <span>Online</span>
            </div>
          )}
        </div>

        {/* Right Side */}
        <div className="flex flex-row items-center gap-4">
          <ParticipantInfo
            session={session}
            selectedSessionEvent={selectedSessionEvent}
            isOwner={isOwner}
            openSessionEventDetailModal={() => setSessionEventDetailModalOpen(true)}
          />

          <button className="btn btn-primary rounded-lg" onClick={() => setSessionEventDetailModalOpen(true)}>
            View
          </button>
        </div>
      </div>

      {user && (
        <SessionEventDetailModal
          isOpen={sessionEventDetailModalOpen}
          setIsOpen={setSessionEventDetailModalOpen}
          session={session}
          selectedEventId={selectedSessionEvent?.id}
          user={user}
          onSessionEventChange={onSessionEventChange}
        />
      )}
    </>
  );
};

export default SessionCardActionBar;
