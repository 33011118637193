import { useMutation, useQuery } from "@tanstack/react-query";
import { PageOptions } from "@yoga-app/db-utils";
import config from "../config";
import { request } from "../utils/api";
import { trpc } from "../utils/trpc";
import { useAuth } from "./auth";

export const useGetPayment = (itemId: string, userId: string, paymentId?: string) => {
  return trpc.payment.getPayment.useQuery(
    { itemId, userId, paymentId },
    {
      enabled: !!itemId && !!userId,
    },
  );
};

export const useQueryPaymentsByUser = (pageOptions: PageOptions) => {
  return trpc.payment.queryPaymentsByUser.useInfiniteQuery(
    { limit: pageOptions.limit },
    {
      enabled: !!pageOptions,
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
      cacheTime: 10 * 60 * 1000,
    },
  );
};

export const useQueryPaymentsByOwnerUser = (pageOptions: PageOptions) => {
  return trpc.payment.queryPaymentsByOwnerUser.useInfiniteQuery(
    { limit: pageOptions.limit },
    {
      enabled: !!pageOptions,
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
      cacheTime: 10 * 60 * 1000,
    },
  );
};

export const useStripeConnectedAccountStatus = () => {
  const { getUserId, getAccessToken } = useAuth();
  const userId = getUserId() as string;
  return useQuery(
    ["accountStatus", userId],
    async () => {
      return request<{ verified: boolean; newAccount: boolean }>(`${config.api.url}/payment/check-account-status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await getAccessToken()}`,
        },
        body: JSON.stringify({ userId }),
      });
    },
    {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  );
};

export const useRefundPayment = () => {
  const { getAccessToken } = useAuth();
  const utils = trpc.useUtils();

  return useMutation(
    async (refundData: { itemId: string; reason: string; payeeUserId: string }) => {
      const response = await request<{ itemId: string; reason: string }>(`${config.api.url}/payment/refund`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await getAccessToken()}`,
        },
        body: JSON.stringify(refundData),
      });

      if (!response) {
        throw new Error("Failed to refund payment");
      }

      return response;
    },
    {
      onSuccess: (_, input) => {
        void utils.payment.getPayment.invalidate({ itemId: input.itemId });
        void utils.session.getSessionEventUsersBySessionEventId.invalidate({ sessionEventId: input.itemId });
        void utils.payment.queryPaymentsByOwnerUser.invalidate();
        void utils.shared.getContentLike.invalidate({ contentId: input.itemId });
      },
    },
  );
};
