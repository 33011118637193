import { z } from "zod";
import { contentItemUri } from "./shared.js";

export const unknownTagSchema = z.object({
  /**
   * The tag which doesn't exist in a category
   */
  tag: z.string(),

  /**
   * the content item if any which the tag was associated with
   */
  contentItemUri: contentItemUri.optional(),

  /**
   * The user id of content owner
   */
  contentOwnerUserId: z.string().optional(),

  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
});

export type UnknownTag = z.infer<typeof unknownTagSchema>;
