interface ActionButtonProps {
  text: string;
  icon?: React.ReactNode;
  className?: string;
  onClick: () => void;
  disabled?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({ text, icon, className = "", onClick, disabled }) => {
  return (
    <button
      className={`btn btn-outline flex items-center space-x-0.5 text-textSecondary text-sm font-bold ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      <span>{text}</span>
    </button>
  );
};

export default ActionButton;
