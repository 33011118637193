import { z } from "zod";

/**
 * Types from https://docs.mapbox.com/api/search/geocoding
 */

/**
 * Mapbox Context Object Schema
 * Represents the hierarchy of encompassing parent features.
 */
export const contextObjectSchema = z.object({
  address: z
    .object({
      mapbox_id: z.string(),
      address_number: z.string().optional(),
      street_name: z.string().optional(),
      name: z.string(),
    })
    .optional(),

  street: z
    .object({
      mapbox_id: z.string(),
      name: z.string(),
    })
    .optional(),

  neighborhood: z
    .object({
      mapbox_id: z.string(),
      name: z.string(),
    })
    .optional(),

  postcode: z
    .object({
      mapbox_id: z.string(),
      name: z.string(),
    })
    .optional(),

  place: z
    .object({
      mapbox_id: z.string(),
      name: z.string(),
      wikidata_id: z.string().optional(),
    })
    .optional(),

  district: z
    .object({
      mapbox_id: z.string(),
      name: z.string(),
      wikidata_id: z.string().optional(),
    })
    .optional(),

  region: z
    .object({
      mapbox_id: z.string(),
      name: z.string(),
      wikidata_id: z.string().optional(),
      region_code: z.string().optional(),
      region_code_full: z.string().optional(),
    })
    .optional(),

  country: z
    .object({
      mapbox_id: z.string(),
      name: z.string(),
      wikidata_id: z.string().optional(),
      country_code: z.string(),
      country_code_alpha_3: z.string().optional(),
    })
    .optional(),

  secondary_address: z
    .object({
      mapbox_id: z.string(),
      name: z.string(),
      designator: z.string(),
      identifier: z.string(),
      extrapolated: z.boolean().optional(),
    })
    .optional(),
});

export type ContextObject = z.infer<typeof contextObjectSchema>;

/**
 * Properties Object Schema (Extends with Context)
 */
export const propertiesObjectSchema = z.object({
  /**
   * Feature id. The mapbox_id uniquely identifies a place in the Mapbox search database. Mapbox ID’s are accepted in requests to the Geocoding API as a forward search, and will return the feature corresponding to that id.
   */
  mapbox_id: z.string(),

  /**
   * A string describing the type of the feature.
   * Options are country, region, postcode, district, place, locality, neighborhood, street, address.
   * Formerly place_type in v5.
   */
  feature_type: z.string(),

  /**
   * Formatted string of address_number and street.
   */
  name: z.string(),
  /**
   * The canonical or otherwise more common alias for the feature name. For example, searching for "America" will return "America" as the name, and "United States" as name_preferred.
   */
  name_preferred: z.string().optional(),
  /**
   * Formatted string of result context: place region country postcode. The part of the result which comes after name.
   */
  place_formatted: z.string().optional(),
  /**
   * Full formatted string of the feature, combining name_preferred and place_formatted.
   */
  full_address: z.string().optional(),
  /**
   * A context object is an object representing the hierarchy of encompassing parent features.
   * This may include a sub-object for any of the following properties: country, region, postcode, district, place, locality, neighborhood, street.
   * Which sub-objects are included is dependent upon the data coverage available and applicable to a given country or area.
   */
  context: contextObjectSchema,
  /**
   * An object representing the geographical position and accuracy of the feature any routable points.
   */
  coordinates: z.object({
    longitude: z.number(),
    latitude: z.number(),
    accuracy: z.string().optional(),
  }),
});

export type PropertiesObject = z.infer<typeof propertiesObjectSchema>;

/**
 * Mapbox Feature Object Schema
 */
export const mapboxFeatureObjectSchema = z.object({
  /**
   * Feature id. This property is named "id" to conform to the GeoJSON specification, but is the same id referred to as mapbox_id elsewhere in the response.
   */
  id: z.string(),

  /**
   * The feature's type.
   */
  type: z.string(),

  /**
   * An object describing the spatial geometry of the returned feature.
   */
  geometry: z.object({
    /**
     * The type of geometry.
     */
    type: z.string(),

    /**
     * An array in the format [longitude,latitude] at the center of the specified bbox.
     */
    coordinates: z.tuple([z.number(), z.number()]),
  }),

  /**
   * An object containing the resulting feature's details.
   */
  properties: propertiesObjectSchema,
});

export type MapboxFeatureObject = z.infer<typeof mapboxFeatureObjectSchema>;

/**
 * Mapbox Geocode Response Schema
 */
export const mapboxGeocodeResponseSchema = z.object({
  /**
   * "FeatureCollection", a GeoJSON type from the GeoJSON specification.
   * https://tools.ietf.org/html/rfc7946
   */
  type: z.string(),
  /**
   * An array of feature objects.
   * Forward geocodes: Returned features are ordered by relevance.
   * Reverse geocodes: Returned features are ordered by index hierarchy, from most specific features to least specific features that overlap the queried coordinates.
   */
  features: z.array(mapboxFeatureObjectSchema),
});

export type MapboxGeocodeResponse = z.infer<typeof mapboxGeocodeResponseSchema>;
