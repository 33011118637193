import { useGetSessionEventUsersBySessionEventId } from "@/src/hooks/session";
import { useGetUser } from "@/src/hooks/user";
import { Session, SessionEvent, SessionEventUser } from "@yoga-app/types";
import ProfilePicture from "../../../../components/profile/ProfilePicture";

interface ParticipantInfoProps {
  session: Session;
  isOwner: boolean;
  selectedSessionEvent?: SessionEvent;
  openSessionEventDetailModal: () => void;
}

export const ParticipantInfo = ({
  session,
  selectedSessionEvent,
  openSessionEventDetailModal,
}: ParticipantInfoProps) => {
  const { data } = useGetSessionEventUsersBySessionEventId(
    selectedSessionEvent?.id ?? "",
    session.userId,
    {
      limit: 3,
    },
    {
      enabled: (selectedSessionEvent?.participantCount ?? 0) > 0,
    },
  );

  const participants = data?.pages.flatMap((page) => page.items) || [];

  //Limit the number of participants to show on the card
  const participantsLimited = participants.slice(0, 3);
  //Work out how many additional participants there are
  const additionalParticipants = participants.length - participantsLimited.length;

  if (!participants.length) {
    return null;
  }

  return (
    <div className="flex items-center">
      <div className="flex flex-row w-full h-full items-center rounded-full" onClick={openSessionEventDetailModal}>
        {/* Participant Avatars */}
        {(selectedSessionEvent?.participantCount ?? 0) > 0 && participants.length > 0 && (
          <div className="h-full text-center justify-center items-center flex -space-x-2">
            {participantsLimited.map((participant) => (
              <ParticipantAvatar key={participant.userId} participant={participant} />
            ))}
            {additionalParticipants > 0 && (
              <div className="w-10 h-10 flex items-center text-accent justify-center text-sm font-semibold rounded-full select-none cursor-pointer">
                +{additionalParticipants}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const ParticipantAvatar = ({ participant }: { participant: SessionEventUser }) => {
  const { data: user } = useGetUser(participant.userId);
  return <ProfilePicture key={user?.id} src={user?.profilePic?.[150]} styles="w-10 h-10 cursor-pointer" />;
};
