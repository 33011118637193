import { Entity, isImageEntity } from "@yoga-app/types";

export const getImageEntityKeys = (entities: Entity[]): string[] => {
  return entities
    .filter(isImageEntity)
    .map((entity) => {
      return entity.url?.[800] || entity.url?.[400] || Object.values(entity.url || {})[0];
    })
    .filter(Boolean);
};
