import MaxWidthWrapper from "@/components/layout/MaxWidthWrapper";

const ProfileNotFound = () => {
  return (
    <MaxWidthWrapper className="flex items-center justify-center h-full">
      <div className="text-center">
        <h1 className="text-3xl font-bold mb-4">Profile Not Found</h1>
        <p className="text-lg">Uh oh, we can't find the profile you were looking for.</p>
      </div>
    </MaxWidthWrapper>
  );
};

export default ProfileNotFound;
