import { ActiveHomeFeedProvider } from "@/contexts/ActiveHomeFeedContext";
import { AuthPromptProvider } from "@/contexts/AuthPromptContext";
import { useAuth } from "@/hooks/auth";
import { useQueryChatsWithUnreadMessages } from "@/hooks/chat";
import { useGetNotifications } from "@/hooks/notification";
import { useGetUser } from "@/hooks/user";
import { dontShowSidebar } from "@/utils/layout";
import { Link, Outlet, useLocation } from "react-router-dom";
import Icon from "../icon/Icon";
import Navbar from "./Navbar";

export default function RootLayout() {
  return (
    <div className={`relative h-screen font-montserrat antialiased font-normal font-sm bg-base-300`}>
      <main className="relative flex flex-col h-screen">
        <Navbar />

        <div className="flex-1 bg-base-300 no-scrollbar overflow-y-hidden overflow-x-hidden h-[calc(100%-4rem)] pb-16 lg:pb-0">
          <div className="relative flex flex-1 h-full">
            <Sidebar />
            <Content />
          </div>
        </div>
      </main>
    </div>
  );
}

const Sidebar = () => {
  const { getUserId, getUsername, isAuthenticated } = useAuth();
  const userId = getUserId() as string;
  const username = getUsername() as string;
  const { data: user, isLoading: isUserLoading } = useGetUser(userId);
  const location = useLocation();

  const { data: unreadChatsData } = useQueryChatsWithUnreadMessages({ limit: 99 });
  const unreadChats = unreadChatsData?.pages.flatMap((page) => page.items) || [];
  const unreadMessagesCount = unreadChats.length;

  const { data: notificationsData } = useGetNotifications(userId, { limit: 99 });
  const notifications = notificationsData?.pages.flatMap((page) => page.items) || [];
  const unreadNotificationsCount = notifications.filter((n) => n.status === "UNREAD").length;

  const links = [
    { href: "/", icon: "home", label: "Home" },
    { href: "/messages", icon: "chat_bubble", label: "Messages", badgeKey: "unreadMessagesCount" },
    { href: "/create-content", icon: "add", label: "Create", isCreate: true },
    { href: "/notifications", icon: "notifications", label: "Notifications", badgeKey: "unreadNotificationsCount" },
    { href: "/my-hub/orders", icon: "orders", label: "Orders" },
  ];

  const bottomLinks = [
    { href: "/edit-profile#monetization", icon: "attach_money", label: "Monetization" },
    { href: `/${username}`, icon: "person", label: "Profile" },
  ];

  const badgeKeyLookup = {
    unreadMessagesCount,
    unreadNotificationsCount,
  };

  if (dontShowSidebar.includes(location.pathname)) {
    return null;
  }

  if (isUserLoading || !user || !isAuthenticated) {
    return null;
  }

  return (
    <>
      {/* Desktop Sidebar (Hidden on mobile) */}
      <nav className="hidden lg:flex fixed left-0 top-0 h-screen w-[4rem] flex-col items-center ml-1">
        <div className="flex flex-col items-center gap-4 flex-1 justify-center">
          {links.map(({ href, icon, badgeKey, isCreate }) => (
            <SidebarLink
              key={href}
              href={href}
              icon={icon}
              location={location}
              badge={badgeKey ? badgeKeyLookup[badgeKey as keyof typeof badgeKeyLookup] : undefined}
              isCreate={isCreate}
            />
          ))}
        </div>
        <div className="flex flex-col items-center gap-4 mb-4">
          {bottomLinks.map(({ href, icon }) => (
            <SidebarLink key={href} href={href} icon={icon} location={location} />
          ))}
        </div>
      </nav>

      {/* Mobile Bottom Navbar (Hidden on desktop) */}
      <nav className="flex lg:hidden fixed bottom-0 left-0 w-full justify-evenly items-center shadow-none bg-white/30 glass backdrop-blur-3xl">
        {links.map(({ href, icon, badgeKey, isCreate }) => (
          <SidebarLink
            key={href}
            href={href}
            icon={icon}
            location={location}
            badge={badgeKey ? badgeKeyLookup[badgeKey as keyof typeof badgeKeyLookup] : undefined}
            isCreate={isCreate}
          />
        ))}
      </nav>
    </>
  );
};

const SidebarLink = ({
  href,
  icon,
  badge,
  location,
  isCreate,
}: {
  href: string;
  icon: string;
  badge?: number;
  location: { pathname: string };
  isCreate?: boolean;
}) => {
  const isActive = location.pathname === href;

  return (
    <Link
      to={href}
      className={`btn btn-ghost btn-sm items-center justify-center w-15 h-15 cursor-pointer z-50 flex rounded-2xl 
      ${isCreate ? "bg-base-100 shadow" : ""}`}
    >
      <div className="indicator cursor-pointer">
        <Icon name={icon} size={isCreate ? 40 : 32} color={isActive ? "#000" : "inherit"} fill={isActive} />
        {badge && badge > 0 ? (
          <span className="indicator-item badge badge-error text-base-100 w-5 h-5 min-h-0 p-1 min-w-0 text-xs">
            {badge > 99 ? "99+" : badge}
          </span>
        ) : null}
      </div>
    </Link>
  );
};

const Content = () => {
  return (
    <div className="flex-1 lg:pl-[5rem] w-full h-full overflow-y-scroll no-scrollbar">
      <AuthPromptProvider>
        <ActiveHomeFeedProvider>
          <Outlet />
        </ActiveHomeFeedProvider>
      </AuthPromptProvider>
    </div>
  );
};
