export const moneyFormatter = Intl.NumberFormat("en-GB", {
  currency: "GBP",
  currencyDisplay: "symbol",
  currencySign: "standard",
  style: "currency",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  minimumIntegerDigits: 1,
  // minimumSignificantDigits: 1,
  // maximumSignificantDigits: 10,
});

export const formatCurrency = (amountInPence: number) => {
  return `£${(amountInPence / 100).toFixed(2)}`;
};

export const formatCount = (count?: number): string => {
  if (count === undefined || count === 0) return "";

  return count.toLocaleString();
};
