import { HTMLInputAutoCompleteAttribute, HTMLInputTypeAttribute, useState } from "react";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";

interface TextInputProps<T extends FieldValues> {
  label: string;
  name: string;
  placeholder?: string;
  register: UseFormRegister<T>;
  error?: string;
  maxLength?: number; // Optional prop
  className?: string;
  autoComplete?: HTMLInputAutoCompleteAttribute;
  type?: HTMLInputTypeAttribute;
}

const TextInput = <T extends FieldValues>({
  label,
  name,
  placeholder,
  register,
  error,
  maxLength,
  className,
  autoComplete,
  type = "text",
}: TextInputProps<T>) => {
  const [charCount, setCharCount] = useState(0);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (maxLength) setCharCount(e.target.value.length);
  };

  return (
    <div className="w-full">
      <label className="block text-sm pl-1">{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        {...register(name as Path<T>)}
        maxLength={maxLength}
        onInput={maxLength ? handleInputChange : undefined}
        className={`input  w-full mt-1 ${error ? "border-error" : "border-gray-300"} ${className}`}
        autoComplete={autoComplete}
      />
      <div className="mt-1 text-sm flex justify-between">
        {maxLength && charCount > 0 && (
          <span className={`${charCount === maxLength ? "text-error" : "text-textSecondary"}`}>
            {charCount}/{maxLength} characters
          </span>
        )}
        {error && <p className="text-error">{error}</p>}
      </div>
    </div>
  );
};

export default TextInput;
