import Loading from "@/src/components/loading/Loading";
import { useActiveHomeFeedContext } from "@/src/hooks/activeHomeFeed";
import { useAuth } from "@/src/hooks/auth";
import { useGetUser } from "@/src/hooks/user";
import { lazy, Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OnboardingModal from "./components/dialog/OnboardingModal";

const MarketingSignUp = lazy(() => import("../marketing/MarketingSignUp"));
const Discovery = lazy(() => import("./screens/Discovery"));
const Following = lazy(() => import("./screens/Following"));

export default function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, getUserId } = useAuth();
  const { activeFeed, setActiveFeed } = useActiveHomeFeedContext();

  const { data: user, isLoading: isUserLoading } = useGetUser(getUserId() as string);

  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const handleCloseOnboarding = () => {
    setIsOnboardingOpen(false);
    navigate({ pathname: location.pathname, hash: "" });
  };

  useEffect(() => {
    if (location.hash === "#onboarding" && user && !isUserLoading && !user?.onboardingComplete) {
      setIsOnboardingOpen(true);
    }
  }, [location.hash, user?.onboardingComplete, user, isUserLoading]);

  if (isLoading) {
    return <Loading className="h-full" />;
  }

  if (!isAuthenticated) {
    return (
      <Suspense fallback={<Loading className="h-full" />}>
        <MarketingSignUp />
      </Suspense>
    );
  }

  if (isAuthenticated && isUserLoading) {
    return <Loading className="h-full" />;
  }

  return (
    <div className="flex flex-col w-full max-w-2xl mx-auto h-auto bg-base-100 rounded-3xl overflow-hidden">
      <div className="w-full flex flex-row text-center justify-evenly pt-4">
        <button
          onClick={() => setActiveFeed("discover")}
          className={`cursor-pointer text-textSecondary transition hover:text-textPrimary ${
            activeFeed === "discover" ? "font-semibold text-textPrimary border-primary border-b-2" : "text-gray-400"
          }`}
        >
          Discover
        </button>
        <button
          onClick={() => setActiveFeed("following")}
          className={`cursor-pointer transition hover:text-textPrimary ${
            activeFeed === "following" ? "font-semibold text-textPrimary border-primary border-b-2" : "text-gray-400"
          }`}
        >
          Following
        </button>
      </div>

      <Suspense fallback={<Loading className="h-full" />}>
        {activeFeed === "discover" && <Discovery />}
        {activeFeed === "following" && <Following />}
      </Suspense>

      {isOnboardingOpen && user && (
        <OnboardingModal isOpen={isOnboardingOpen} onClose={handleCloseOnboarding} user={user} />
      )}
    </div>
  );
}
