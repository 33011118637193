import Icon from "@/components/icon/Icon";
import { useNavigate } from "react-router-dom";

interface MessageButtonProps {
  userId: string;
  className?: string;
  iconSize?: number;
}

export const MessageButton = ({ userId, className, iconSize = 22 }: MessageButtonProps) => {
  const navigate = useNavigate();
  return (
    <button
      className={`btn btn-outline border-[1px] border-borderSecondary/30 shadow p-6 btn-circle text-info ${className}`}
      onClick={() => navigate(`/messages?userId=${userId}`)}
    >
      <Icon name="chat_bubble" size={iconSize} color="inherit" fill />
    </button>
  );
};
