import { Link } from "react-router-dom";
import Icon from "../icon/Icon";

interface CalendarButtonProps {
  username: string;
  iconSize?: number;
}

export const CalendarButton = ({ username, iconSize = 22 }: CalendarButtonProps) => {
  return (
    <Link
      to={`/${username}/calendar`}
      className="btn btn-outline border-[1px] border-borderSecondary/30 shadow p-6 btn-circle text-textPrimary"
    >
      <Icon name="calendar_month" size={iconSize} color="inherit" />
    </Link>
  );
};
