import Modal from "@/components/dialog/Modal";
import Icon from "@/components/icon/Icon";
import { Link } from "react-router-dom";

interface AuthPromptModalProps {
  isOpen: boolean;
  onClose: () => void;
  iconName: string;
  headerText: string;
  subheaderText: string;
}

const AuthPromptModal = ({ isOpen, onClose, iconName, headerText, subheaderText }: AuthPromptModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Join in" className="md:w-[450px]">
      <div className="w-full flex flex-col items-center text-center p-4">
        <Icon name={iconName} size={38} color="#374151" />

        <h2 className="text-xl font-semibold my-2">{headerText}</h2>

        <p className="text-textSecondary mb-6">{subheaderText}</p>

        <div className="flex space-x-4">
          <Link to={"/sign-in"} onClick={onClose} className="btn btn-primary text-base-100 font-semibold">
            Sign in
          </Link>
          <Link to={"/sign-up"} onClick={onClose} className="btn btn-outline font-semibold">
            Sign up
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default AuthPromptModal;
