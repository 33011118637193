import Modal from "@/components/dialog/Modal";
import { useCancelSessionEventMutation } from "@/hooks/session";
import { Session, SessionEvent, User } from "@yoga-app/types";

interface CancelModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  session: Session;
  selectedEvent: SessionEvent;
  user: User;
}

export const CancelModal = ({ isOpen, setIsOpen, session, selectedEvent, user }: CancelModalProps) => {
  const cancelSessionEventMutation = useCancelSessionEventMutation();

  const handleCancelEvent = async () => {
    if (!selectedEvent) return;

    await cancelSessionEventMutation.mutateAsync({
      sessionId: session.id,
      startDateTime: selectedEvent.startDateTime,
      sessionEventId: selectedEvent.id,
      userId: user.id,
    });

    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} title="Cancel Event" className="md:max-w-sm h-auto">
      <div className="p-6 pt-0 text-center text-textSecondary">
        <p>Are you sure you want to cancel this event?</p>
        <p className="mt-1 text-error">
          Cancelling this event will stop new sign-ups and notify them of this change. Any existing payments will need
          to be refunded. Please review all orders before cancelling.
        </p>

        <div className="flex justify-center gap-3 mt-6">
          <button
            className="btn btn-error text-base-100 px-6 flex items-center justify-center"
            onClick={handleCancelEvent}
            disabled={cancelSessionEventMutation.isLoading}
          >
            {cancelSessionEventMutation.isLoading ? (
              <span className="loading loading-spinner mr-2"></span>
            ) : (
              "Cancel Event"
            )}
          </button>
          <button
            className="btn btn-base-100 px-6"
            onClick={() => setIsOpen(false)}
            disabled={cancelSessionEventMutation.isLoading}
          >
            Back
          </button>
        </div>
      </div>
    </Modal>
  );
};
