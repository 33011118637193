import { z } from "zod";

export const coordinatesSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
});

export type Coordinates = z.infer<typeof coordinatesSchema>;

export const userLocationSchema = z.object({
  userId: z.string(),
  addressLine1: z.string(),
  addressLine2: z.string().optional(),
  city: z.string(),
  state: z.string().optional(),
  postalCode: z.string(),
  country: z.string(),
  coordinates: z.object({
    latitude: z.number(),
    longitude: z.number(),
  }),
  formattedAddress: z.string(),
  h3_res4: z.string(),
  h3_res5: z.string(),
  h3_res6: z.string(),
  h3_res7: z.string(),

  /**
   * The date and time the content was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),

  /**
   * The date and time the content was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});

export type UserLocation = z.infer<typeof userLocationSchema>;
