import ContentBadge from "@/components/badge/ContentBadge";
import InfiniteList from "@/components/list/InfiniteList";
import { useGetPackageItems } from "@/hooks/package";
import PackageItemCard from "./PackageItemCard";

interface PackageItemListProps {
  packageId: string;
}

const PackageItemList = ({ packageId }: PackageItemListProps) => {
  const { data, fetchNextPage, hasNextPage, isFetching } = useGetPackageItems(packageId, [], { limit: 4 });

  const packageItems = data?.pages.flatMap((page) => page.items) || [];

  return (
    <div className="w-full mb-4 bg-gray-200/75 shadow-xs rounded-xl p-1 px-4">
      <div className="pt-2 pl-2">
        <ContentBadge type="PACKAGE" className="bg-base-100 py-4 pr-4 outline-hidden border-none text-accent" />
      </div>

      <div className="p-2 pt-4 overflow-hidden w-full">
        <div className="overflow-x-scroll no-scrollbar">
          <InfiniteList
            data={packageItems}
            fetchNextPage={async () => {
              await fetchNextPage();
            }}
            isFetching={isFetching}
            hasNextPage={!!hasNextPage}
            CardComponent={PackageItemCard}
            direction="horizontal"
            className="space-x-9"
          />
        </div>
      </div>
    </div>
  );
};

export default PackageItemList;
