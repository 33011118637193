import logo from "@/assets/logo180.png";
import { useAuth } from "@/hooks/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGetUser } from "../../hooks/user";
import Icon from "../icon/Icon";
import ProfilePicture from "../profile/ProfilePicture";

// Pages to exclude navbar links
const excludeNavBarLinks = ["/sign-in", "/sign-up"];

const Navbar = () => {
  const location = useLocation();
  const { isLoading, isAuthenticated, getUserId, signOut } = useAuth();
  const navigate = useNavigate();
  const userId = getUserId() as string;
  const { data: user, isLoading: isUserLoading } = useGetUser(userId);

  const handleSignOut = () => {
    signOut();
    navigate("/");
  };

  return (
    <div className="navbar sticky inset-x-0 top-0 z-50 h-16 w-full px-4">
      <header className="relative w-full">
        <div className="w-full">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <Link to="/" className={`btn btn-ghost flex items-center justify-center`}>
                <img
                  src={logo}
                  alt="bodhiga Logo"
                  className="h-24 justify-center items-center object-fill overflow-hidden"
                />
              </Link>
            </div>

            {!excludeNavBarLinks.includes(location.pathname) && (
              <div className="flex items-center space-x-3">
                {isLoading || (isAuthenticated && isUserLoading) ? null : isAuthenticated && user ? (
                  // user logged in
                  <>
                    {/* User dropdown */}
                    <div className="dropdown dropdown-end">
                      <label
                        tabIndex={0}
                        className="flex items-center gap-2 p-2 bg-base-100 rounded-full cursor-pointer hover:bg-accent transition"
                      >
                        <ProfilePicture src={user?.profilePic?.[150]} styles="w-8 h-8 rounded-full" />
                        <span className="font-medium text-base text-base-content max-w-sm truncate">
                          {user?.displayName || user?.username}
                        </span>
                        <svg
                          className="w-4 h-4 text-base-content"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                        </svg>
                      </label>

                      <ul tabIndex={0} className="dropdown-content menu mt-2 p-2 shadow bg-base-100 rounded-box w-52">
                        <li>
                          <Link to={`/${user?.username}`} className="text-md">
                            Profile
                          </Link>
                        </li>
                        <li>
                          <button onClick={handleSignOut} className="text-md">
                            Sign out
                          </button>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  // user not logged in
                  <>
                    {/* Menu dropdown on smaller screens */}
                    <div className="dropdown dropdown-end lg:hidden">
                      <label tabIndex={0} className="btn btn-ghost">
                        <Icon name="menu" color="#212529" />
                      </label>
                      <ul tabIndex={0} className="dropdown-content menu p-2 shadow-sm bg-base-100 rounded-box w-52">
                        <li>
                          <button className="btn btn-ghost text-md" onClick={() => navigate("/sign-in")}>
                            Sign in
                          </button>
                        </li>

                        <li>
                          <button className="btn btn-ghost text-md" onClick={() => navigate("/sign-up")}>
                            Create account
                          </button>
                        </li>
                      </ul>
                    </div>
                    {/* Menu items on larger screens */}
                    <div className="hidden lg:flex lg:items-center lg:space-x-4">
                      <button className="btn btn-ghost text-md" onClick={() => navigate("/sign-in")}>
                        Sign in
                      </button>
                      <>
                        <div className="divider divider-horizontal"></div>
                        <button className="btn btn-ghost text-md" onClick={() => navigate("/sign-up")}>
                          Create account
                        </button>
                      </>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
