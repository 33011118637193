import { z } from "zod";
import { contentSchema } from "./shared.js";

/**
 * A post is essentially a container that can include text and media content.
 */
export const postSchema = contentSchema
  //Ensures that every post must contain either text or at least one entity (e.g., image, video).
  .refine((data) => data.text || (data.entities && data.entities.length > 0), {
    message: "Posts must contain content. Either text or entities.",
  })
  // Ensure entity positions are unique and sequential
  .refine(
    (data) => {
      if (data.entities) {
        const positions = data.entities.map((entity) => entity.position);
        const uniquePositions = new Set(positions);
        const isSequential = positions.sort((a, b) => a - b).every((value, index) => value === index);
        return uniquePositions.size === positions.length && isSequential;
      }
      return true;
    },
    {
      message: "Entities must have unique and sequential positions starting from 0.",
    },
  );

export type Post = z.infer<typeof postSchema>;
