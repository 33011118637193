import { useGetSessionEvent, useQuerySessionEventsBySessionId } from "@/hooks/session";
import { SessionEvent } from "@yoga-app/types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Icon from "../../../../components/icon/Icon";

interface SessionEventSelectProps {
  sessionId: string;
  userId: string;
  selectedEventId?: string;
  selectedSessionEvent?: SessionEvent;
  onSessionEventChange: (sessionEvent: SessionEvent) => void;
}

const todaysDate = new Date();
todaysDate.setHours(0, 0, 0, 0);

const SessionEventSelect = ({
  sessionId,
  userId,
  selectedEventId,
  selectedSessionEvent,
  onSessionEventChange,
}: SessionEventSelectProps) => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const sessionEventId = searchParams.get("sessionEventId");
  const [fromDate, setFromDate] = useState<string>();
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);

  // Get the specific session event if sessionEventId is passed in through the url
  const { data: specificEvent, isLoading: isSpecificEventLoading } = useGetSessionEvent(
    userId,
    sessionEventId as string,
  );

  useEffect(() => {
    if (!sessionEventId || !isSpecificEventLoading) {
      if (specificEvent) {
        const eventStartDate = new Date(specificEvent.startDateTime);
        eventStartDate.setHours(0, 0, 0, 0);
        setFromDate(eventStartDate.toISOString());
      } else {
        setFromDate(todaysDate.toISOString());
      }
    }
  }, [specificEvent, isSpecificEventLoading, sessionEventId]);

  // Fetch past events
  const {
    data: pastData,
    fetchNextPage: fetchPastPage,
    hasNextPage: hasMorePastEvents,
    isFetching: isFetchingPast,
    refetch: refetchPastEvents,
  } = useQuerySessionEventsBySessionId(sessionId, fromDate ?? "", "PAST", true, { limit: 10 });

  // Fetch future events
  const {
    data: futureData,
    fetchNextPage: fetchFuturePage,
    hasNextPage: hasMoreFutureEvents,
    isFetching: isFetchingFuture,
    refetch: refetchFutureEvents,
  } = useQuerySessionEventsBySessionId(sessionId, fromDate ?? "", "FUTURE", true, { limit: 10 });

  const [shouldScroll, setShouldScroll] = useState(true);

  const pastEvents = useMemo(() => pastData?.pages.flatMap((page) => page.items) || [], [pastData]);
  const futureEvents = useMemo(() => futureData?.pages.flatMap((page) => page.items) || [], [futureData]);

  // Combine past and future events, sorted by date
  const combinedEvents = useMemo(
    () =>
      [...pastEvents, ...futureEvents].sort(
        (a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime(),
      ),
    [pastEvents, futureEvents],
  );

  const selectedIndex = useMemo(
    () => combinedEvents.findIndex((event) => event.id === selectedEventId),
    [combinedEvents, selectedEventId],
  );

  useEffect(() => {
    if (
      combinedEvents.length === 0 &&
      !isFetchingPast &&
      !isFetchingFuture &&
      !pollingIntervalRef.current &&
      !!fromDate
    ) {
      /**
       * Polling interval to refetch past and future events every 2 seconds when there are no events.
       * A session should always have atleast 1 event, we're doing this polling where no events are present yet due to the delay in creating session events on Session Create
       */
      pollingIntervalRef.current = setInterval(async () => {
        await Promise.all([refetchPastEvents(), refetchFutureEvents()]);
      }, 2000);

      setTimeout(() => {
        if (pollingIntervalRef.current) {
          clearInterval(pollingIntervalRef.current);
          pollingIntervalRef.current = null;
        }
      }, 30000);
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    };
  }, [combinedEvents.length, isFetchingPast, isFetchingFuture, refetchPastEvents, refetchFutureEvents, fromDate]);

  useEffect(() => {
    if (!selectedEventId && !selectedSessionEvent) {
      if (specificEvent) {
        onSessionEventChange(specificEvent);
      } else {
        const nextUpcomingEvent = futureEvents[0];
        const defaultEvent = nextUpcomingEvent || combinedEvents[0];

        if (defaultEvent) {
          onSessionEventChange(defaultEvent);
        }
      }
    }
  }, [futureEvents, combinedEvents, selectedSessionEvent, onSessionEventChange, specificEvent, selectedEventId]);

  useEffect(() => {
    if (shouldScroll) {
      setShouldScroll(false);
    }
  }, [combinedEvents, shouldScroll]);

  if (!combinedEvents.length && !isFetchingPast && !isFetchingFuture) {
    return null;
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    // Scroll to top: Load past events
    if (scrollTop === 0 && hasMorePastEvents && !isFetchingPast) {
      void fetchPastPage();
    }

    // Scroll to bottom: Load future events
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMoreFutureEvents && !isFetchingFuture) {
      void fetchFuturePage();
    }
  };

  return (
    <div className="relative w-full z-50">
      {/* Dropdown trigger */}
      <button
        type="button"
        className="border w-full px-4 py-2 text-left bg-white rounded-md focus:ring"
        onClick={() => setDropdownOpen(!isDropdownOpen)}
      >
        {selectedSessionEvent
          ? `${new Date(selectedSessionEvent.startDateTime).toLocaleString()}`
          : "Select a session event"}
        <Icon name="keyboard_arrow_down" className="float-right" />
      </button>

      {/* Dropdown list */}
      {isDropdownOpen && (
        <div
          ref={listRef}
          onScroll={handleScroll}
          className="absolute w-full max-h-30 overflow-auto bg-white border mt-1 rounded-md shadow-lg"
          onMouseDown={(e) => e.stopPropagation()}
        >
          {combinedEvents.map((event, index) => (
            <div
              key={event.id}
              className={`btn btn-ghost w-full p-2 cursor-pointer ${selectedIndex === index ? "bg-primary text-white" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSessionEventChange(event);
                setDropdownOpen(false);
              }}
            >
              {new Date(event.startDateTime).toLocaleString()}
            </div>
          ))}

          {/* Loading indicators */}
          {(isFetchingPast || isFetchingFuture) && (
            <div className="p-2 text-center text-gray-500">
              <span className="loading loading-spinner"></span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SessionEventSelect;
