import Modal from "@/components/dialog/Modal";
import Icon from "@/components/icon/Icon";
import ProfilePicture from "@/components/profile/ProfilePicture";
import { useAuth, useAuthPrompt } from "@/hooks/auth";
import { useGetPayment } from "@/hooks/payment";
import { useGetSessionEvent, useGetSessionEventUsersBySessionEventId } from "@/hooks/session";
import SessionEventSelect from "@/src/pages/content/components/select/SessionEventSelect";
import { formatDateRange, formatDuration } from "@/utils/date";
import { moneyFormatter } from "@/utils/formatters";
import { getContentPageLink } from "@/utils/navigation";
import { checkIfSessionEventCanBePurchased } from "@/utils/payment";
import { Session, SessionEvent, User } from "@yoga-app/types";
import { useState } from "react";
import { Link } from "react-router-dom";
import ParticipantCard from "./components/card/ParticipantCard";
import { CancelModal } from "./components/dialog/CancelModal";

interface SessionEventDetailModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  session?: Session;
  user: User;
  selectedEventId?: string;
  onSessionEventChange: (sessionEvent: SessionEvent) => void;
}

const SessionEventDetailModal = ({
  isOpen,
  setIsOpen,
  selectedEventId,
  user,
  session,
  onSessionEventChange,
}: SessionEventDetailModalProps) => {
  const { getUserId, isAuthenticated } = useAuth();
  const { openAuthPromptModal } = useAuthPrompt();
  const currentUserId = getUserId();
  const isOwner = currentUserId === user.id;
  const { data: selectedEvent } = useGetSessionEvent(user.id, selectedEventId as string);
  const { data, fetchNextPage, hasNextPage, isFetching } = useGetSessionEventUsersBySessionEventId(
    selectedEventId as string,
    user.id,
    { limit: 10 },
  );
  const formattedPrice =
    session?.price === undefined && selectedEvent?.price == undefined
      ? "N/A"
      : moneyFormatter.format(((session?.price as number) ?? (selectedEvent?.price as number)) / 100);
  const { data: paymentData } = useGetPayment(selectedEvent?.id || "", getUserId() as string);
  const isPaid = paymentData?.paymentRecordFound ? paymentData.payment?.status === "COMPLETED" : false;
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);

  const participants =
    data?.pages.flatMap((page) =>
      page.items.map((item) => ({
        id: item.userId,
        ...item,
        sessionOwnerUserId: selectedEvent?.sessionOwnerUserId ?? "",
      })),
    ) || [];

  if (!session && !selectedEvent) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={selectedEvent?.title ?? "Event Details"}
      className="h-full md:max-w-xl md:h-auto"
    >
      <div className="w-full h-auto relative p-4 space-y-3">
        {/* Cancellation Alert */}
        {selectedEvent?.cancelled && (
          <div role="alert" className="alert alert-info flex items-center p-3 rounded-md">
            <Icon name="info" size={24} className="mr-2 mb-1" />
            <span>This event has been cancelled.</span>
          </div>
        )}

        {/* Settings Dropdown */}
        {currentUserId === user.id &&
          selectedEvent &&
          !selectedEvent.cancelled &&
          new Date(selectedEvent.endDateTime) > new Date() && (
            <div className="absolute right-2 top-2 dropdown dropdown-end">
              <div tabIndex={0} role="button" className="p-2">
                <Icon name="more_vert" size={24} className="cursor-pointer select-none mb-1" />
              </div>
              <ul className="dropdown-content bg-white rounded-lg shadow-md w-40 mt-2 border border-gray-200 text-sm">
                <li>
                  <button
                    onClick={() => setCancelModalOpen(true)}
                    className="w-full text-left px-4 py-2 text-error hover:bg-red-50"
                  >
                    Cancel Event
                  </button>
                </li>
              </ul>
            </div>
          )}

        {/* User Info */}
        <Link to={`/${user.username}`} className="flex items-center space-x-3">
          <ProfilePicture src={user.profilePic?.[150]} styles="w-14 h-14 rounded-full" />
          <div className="flex flex-col">
            <span className="font-bold text-lg text-textPrimary">{user.displayName ?? user.username}</span>
            <span className="text-sm text-textNeutral">@{user.username}</span>
          </div>
        </Link>

        <div className="w-full space-y-2 p-2">
          {/* Session Title */}
          <h2 className="text-xl font-semibold">{session?.title}</h2>

          {/* Location Info */}
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
              <Icon
                name={(session?.locationType || selectedEvent?.locationType) === "online" ? "videocam" : "location_on"}
                size={24}
                className="mb-1"
              />
              <span className="text-textSecondary">{session?.locationType === "online" ? "Online" : "In-Person"}</span>
            </div>

            {(session?.locationType || selectedEvent?.locationType) === "in-person" && session?.formattedAddress && (
              <div className="flex items-center space-x-2">
                <Icon name="location_on" color="#212529" size={24} className="mb-1" />
                <span className="text-textSecondary">{session.formattedAddress}</span>
              </div>
            )}
          </div>

          {/* Duration */}
          <div className="flex items-center space-x-2">
            <Icon name="schedule" color="#212529" size={24} className="mb-1" />
            <span className="text-textSecondary">
              {formatDuration((session?.duration as number) ?? (selectedEvent?.duration as number))}
            </span>
          </div>

          {/* Date & Time */}
          {selectedEvent && (
            <div className="flex items-center space-x-2">
              <Icon name="calendar_month" size={24} className="mb-1" />
              <div className="text-textSecondary text-sm md:text-base">
                <span>
                  {new Date(selectedEvent.startDateTime).toLocaleDateString("en-GB", {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </span>
                <span className="mx-2 text-textPrimary">•</span>
                <span>
                  {formatDateRange(selectedEvent.startDateTime, selectedEvent.endDateTime, {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </span>
              </div>
            </div>
          )}

          {/* Event Selector */}
          <div className="lg:w-1/2">
            <SessionEventSelect
              sessionId={(session?.id || selectedEvent?.sessionId) as string}
              userId={user.id}
              selectedEventId={selectedEventId}
              selectedSessionEvent={selectedEvent ?? undefined}
              onSessionEventChange={onSessionEventChange}
            />
          </div>
        </div>

        {/* Participants List */}
        <div className="border border-gray-300 rounded-lg my-4 mb-8">
          <h4 className="font-semibold text-sm md:text-base p-4">Participants</h4>
          <div className="border-b border-gray-300"></div>

          <div className="flex flex-col p-2 space-y-3 max-h-60 overflow-y-auto">
            {participants.length > 0 ? (
              participants.map((participant) => <ParticipantCard key={participant.id} item={participant} />)
            ) : (
              <div className="text-center text-sm text-gray-500">No participants yet.</div>
            )}

            {hasNextPage && (
              <div className="flex justify-center">
                <button onClick={() => fetchNextPage()} disabled={isFetching} className="text-neutral hover:underline">
                  {isFetching ? "Loading..." : "View More"}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between items-end w-full">
          {/* View Session Button */}
          <div>
            <Link
              className="btn btn-neutral"
              to={
                selectedEvent
                  ? `/${user.username}/session/${selectedEvent?.sessionId}?sessionEventId=${selectedEvent?.id}`
                  : "#"
              }
            >
              View Session
            </Link>
          </div>

          {/* Payment/order Logic */}
          <div>
            {isOwner
              ? (() => {
                  const { canPurchase, message, shortMessage } = checkIfSessionEventCanBePurchased(
                    selectedEvent ?? undefined,
                  );

                  return !canPurchase ? (
                    <button className="btn btn-disabled text-primary" title={message ?? "Not Available"}>
                      {shortMessage}
                    </button>
                  ) : (
                    <button className="btn btn-primary flex items-center text-white">
                      Tickets Available - {formattedPrice}
                      <Icon name="chevron_right" color="inherit" size={18} className="mb-0.5" />
                    </button>
                  );
                })()
              : (() => {
                  if (!isAuthenticated) {
                    return (
                      <button
                        className="btn btn-primary flex items-center text-white"
                        onClick={(e) => {
                          e.preventDefault();
                          openAuthPromptModal("buy_tickets");
                        }}
                      >
                        Buy Tickets - {formattedPrice}
                        <Icon name="chevron_right" color="inherit" size={18} className="mb-0.5" />
                      </button>
                    );
                  }

                  const { canPurchase, message, shortMessage } = checkIfSessionEventCanBePurchased(
                    selectedEvent ?? undefined,
                  );
                  const checkoutLink =
                    selectedEvent &&
                    `${getContentPageLink(session?.username ?? selectedEvent?.sessionOwnerUsername, session?.id ?? selectedEvent?.sessionId, session?.type ?? "SESSION")}?sessionEventId=${selectedEvent.id}#checkout`;

                  if (isPaid) {
                    return (
                      <Link to={checkoutLink ?? ""} className="btn btn-info flex items-center text-white">
                        View Ticket
                        <Icon name="chevron_right" color="inherit" size={18} className="mb-0.5" />
                      </Link>
                    );
                  }

                  return !canPurchase ? (
                    <button className="btn btn-disabled text-primary" title={message ?? "Not Available"}>
                      {shortMessage}
                    </button>
                  ) : (
                    <Link to={checkoutLink ?? ""} className="btn btn-primary flex items-center text-white">
                      Buy Tickets - {formattedPrice}
                      <Icon name="chevron_right" color="inherit" size={18} className="mb-0.5" />
                    </Link>
                  );
                })()}
          </div>
        </div>
      </div>

      {/* Cancel Modal */}
      {selectedEvent && session && (
        <CancelModal
          isOpen={isCancelModalOpen}
          setIsOpen={setCancelModalOpen}
          session={session}
          selectedEvent={selectedEvent}
          user={user}
        />
      )}
    </Modal>
  );
};

export default SessionEventDetailModal;
