import Icon from "@/components/icon/Icon";
import { useNavigate } from "react-router-dom";

interface EditProfileButtonProps {
  iconSize?: number;
}

export const EditProfileButton = ({ iconSize = 22 }: EditProfileButtonProps) => {
  const navigate = useNavigate();

  return (
    <button
      className={`btn btn-outline border-[1px] border-borderSecondary/30 shadow p-6 btn-circle text-textPrimary`}
      onClick={() => navigate(`/edit-profile`)}
    >
      <Icon name="edit" size={iconSize} color="inherit" />
    </button>
  );
};
