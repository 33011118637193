import { useAuth } from "@/hooks/auth";
import { useGetUser } from "@/hooks/user";
import React from "react";
import { useNavigate } from "react-router-dom";

interface ProtectedAdminRouteProps {
  children: React.ReactNode;
}

const ProtectedAdminRoute: React.FC<ProtectedAdminRouteProps> = ({ children }) => {
  const { isAuthenticated, isLoading, getUserId } = useAuth();
  const { data: user, isLoading: userLoading } = useGetUser(getUserId() as string);
  const navigate = useNavigate();

  if (isLoading || userLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  if (!isAuthenticated || !user?.admin) {
    navigate("/");
    return null;
  }

  return <>{children}</>;
};

export default ProtectedAdminRoute;
