import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { Suspense, lazy, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ProtectedAdminRoute from "./components/admin/ProtectedAdminRoute";
import AdminDashboardLayout from "./components/admin/layout/AdminDashboardLayout";
import GlobalErrorBoundary from "./components/error/GlobalErrorBoundary";
import PageNotFound from "./components/error/PageNotFound";
import ProtectedRoute from "./components/layout/ProtectedRoute";
import RootLayout from "./components/layout/RootLayout";
import Loading from "./components/loading/Loading";
import { useAuth } from "./hooks/auth";
import ReportedContent from "./pages/admin/ReportedContent";
import Home from "./pages/home/Home";
import ProfileLayout from "./pages/profile/layout/ProfileLayout";
import CookiePolicy from "./pages/support/legal/CookiePolicy";
import PrivacyPolicy from "./pages/support/legal/PrivacyPolicy";
import TermsOfService from "./pages/support/legal/TermsOfService";
import { trpc, useTrpcClient } from "./utils/trpc";

// Lazy load the page components
const Profile = lazy(() => import("./pages/profile/pages/Profile"));
const ForgotPassword = lazy(() => import("./pages/auth/pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/auth/pages/ResetPassword"));
const Signin = lazy(() => import("./pages/auth/pages/Signin"));
const Signup = lazy(() => import("./pages/auth/pages/Signup"));
const ConfirmSignup = lazy(() => import("./pages/auth/pages/ConfirmSignup"));
const Post = lazy(() => import("./pages/content/pages/Post"));
const Package = lazy(() => import("./pages/content/pages/Package"));
const Session = lazy(() => import("./pages/content/pages/Session"));
const EditProfile = lazy(() => import("./pages/profile/pages/EditProfile"));
const Community = lazy(() => import("./pages/community/Community"));
const Messages = lazy(() => import("./pages/messages/Messages"));
const Notifications = lazy(() => import("./pages/notifications/Notifications"));
const Calendar = lazy(() => import("./pages/calendar/Calendar"));
const CreateContent = lazy(() => import("./pages/content/pages/CreateContent"));
const Orders = lazy(() => import("./pages/my-hub/pages/Orders"));
const Order = lazy(() => import("./pages/my-hub/pages/Order"));
const CreateMembership = lazy(() => import("./pages/membership/CreateMembership"));
const ProfileMemberships = lazy(() => import("./pages/profile/pages/Memberships"));
const MyHub = lazy(() => import("./pages/my-hub/pages/MyHub"));
const UnknownTags = lazy(() => import("./pages/admin/UnknownTags"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <GlobalErrorBoundary />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: "edit-profile",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <EditProfile />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: "create-content",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <CreateContent />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: "create-membership",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <CreateMembership />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: ":username/calendar",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <Calendar />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: "messages",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <Messages />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: "notifications",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <Notifications />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: "my-hub",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <MyHub />
            </ProtectedRoute>
          </Suspense>
        ),
        children: [
          {
            path: "orders",
            element: (
              <Suspense fallback={<Loading className="h-screen" />}>
                <Orders />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "order/:itemId/:userId",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <Order />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: "sign-in",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Signin />
          </Suspense>
        ),
      },
      {
        path: "sign-up",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Signup />
          </Suspense>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ForgotPassword />
          </Suspense>
        ),
      },
      {
        path: "reset-password",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ResetPassword />
          </Suspense>
        ),
      },
      {
        path: "confirm-signup",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ConfirmSignup />
          </Suspense>
        ),
      },
      {
        path: "admin",
        element: (
          <ProtectedAdminRoute>
            <Suspense fallback={<Loading className="h-screen" />}>
              <AdminDashboardLayout />
            </Suspense>
          </ProtectedAdminRoute>
        ),
        children: [
          {
            path: "reported-content",
            element: (
              <Suspense fallback={<Loading className="h-screen" />}>
                <ProtectedAdminRoute>
                  <ReportedContent />
                </ProtectedAdminRoute>
              </Suspense>
            ),
          },
          {
            path: "unknown-tags",
            element: (
              <Suspense fallback={<Loading className="h-screen" />}>
                <ProtectedAdminRoute>
                  <UnknownTags />
                </ProtectedAdminRoute>
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "terms-of-service",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <TermsOfService />
          </Suspense>
        ),
      },
      {
        path: "privacy-policy",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <PrivacyPolicy />
          </Suspense>
        ),
      },
      {
        path: "cookie-policy",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <CookiePolicy />
          </Suspense>
        ),
      },
      {
        path: "community/:hashtag",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <Community />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: ":username/post/:postId",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Post />
          </Suspense>
        ),
      },
      {
        path: ":username/package/:packageId",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Package />
          </Suspense>
        ),
      },
      {
        path: ":username/session/:sessionId",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Session />
          </Suspense>
        ),
      },
      {
        path: ":username",
        element: <ProfileLayout />,
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Loading className="h-screen" />}>
                <Profile />
              </Suspense>
            ),
          },
          {
            path: "memberships",
            element: (
              <Suspense fallback={<Loading className="h-screen" />}>
                <ProfileMemberships />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "*",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <PageNotFound />
          </Suspense>
        ),
      },
    ],
  },
]);

export function App() {
  const { refreshSession, getRefreshToken, signOut } = useAuth();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: (failureCount, error: unknown) => {
              if (
                (error as Error)?.message?.toLowerCase()?.includes("jwt expired") ||
                (error as Error)?.message?.toLowerCase()?.includes("jwt verification failed") ||
                (error as Error)?.message?.toLowerCase()?.includes("unable to transform response")
              ) {
                // Do not retry for JWT-related errors
                return false;
              }
              // Allow retries for other errors, but limit to a maximum count
              return failureCount < 3; // Retry up to 3 times for other errors
            },
            onError: async (error: unknown) => {
              if (
                (error as Error)?.message?.toLowerCase()?.includes("unable to transform response") ||
                (error as Error)?.message?.toLowerCase()?.includes("jwt expired") ||
                (error as Error)?.message?.toLowerCase()?.includes("jwt verification failed")
              ) {
                try {
                  const refreshToken = getRefreshToken();

                  if (!refreshToken) {
                    signOut();
                    return;
                  }
                  await refreshSession(refreshToken);
                  await queryClient.refetchQueries();
                } catch (e) {
                  console.error("Session refresh failed", e);
                  signOut();
                }
              }
            },
          },
        },
      }),
  );

  const trpcClient = useTrpcClient();

  const persister = createSyncStoragePersister({
    storage: window.localStorage,
  });

  void persistQueryClient({
    queryClient,
    persister,
    maxAge: Infinity,
  });

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </trpc.Provider>
  );
}
