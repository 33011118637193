import { MessageButton } from "@/components/button/MessageButton";
import Icon from "@/components/icon/Icon";
import ProfilePicture from "@/components/profile/ProfilePicture";
import { useAuth } from "@/hooks/auth";
import { useGetUser } from "@/hooks/user";
import { SessionEventUser } from "@yoga-app/types";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { Link, useNavigate } from "react-router-dom";
import { v4 } from "uuid";

interface ParticipantCardProps {
  item: SessionEventUser & { sessionOwnerUserId: string };
}

const ParticipantCard = ({ item }: ParticipantCardProps) => {
  const { getUserId } = useAuth();
  const currentUserId = getUserId() as string;
  const { data: user } = useGetUser(item.userId);
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [triggerRender, setTriggerRender] = useState(v4());

  const { styles, attributes, update } = usePopper(buttonRef.current, popperElement, {
    placement: "bottom-end",
    modifiers: [
      { name: "preventOverflow", options: { boundary: "clippingParents" } },
      { name: "flip", options: { fallbackPlacements: ["top-end"] } },
    ],
  });

  useEffect(() => {
    if (dropdownOpen) {
      setTriggerRender(v4());
      setTimeout(() => {
        void update?.();
      }, 0);
    }
  }, [dropdownOpen, update]);

  if (!user) return null;

  return (
    <div className="flex items-center justify-between p-1">
      <Link to={`/${user.username}`} className="flex items-center space-x-3">
        <ProfilePicture src={user.profilePic?.[150]} styles="w-12 h-12 rounded-full" />
        <div className="flex flex-col">
          <span className="font-medium text-textPrimary text-sm">{user.displayName ?? user.username}</span>
          <span className="text-xs text-textSecondary">@{user.username}</span>
        </div>
      </Link>

      <div className="flex items-center space-x-2 relative">
        {user.id !== currentUserId && (
          <MessageButton userId={user.id} iconSize={20} className="w-9 h-9 flex items-center justify-center" />
        )}

        <button
          ref={buttonRef}
          onClick={() => setDropdownOpen((prev) => !prev)}
          className="w-9 h-9 flex items-center justify-center rounded-full hover:bg-gray-100"
        >
          <Icon name="more_vert" size={20} className="cursor-pointer select-none" />
        </button>

        {dropdownOpen &&
          createPortal(
            <div
              key={triggerRender}
              ref={(el) => {
                setPopperElement(el);
                dropdownRef.current = el;
              }}
              className="bg-white rounded-lg shadow-lg border border-gray-200 w-40 text-sm z-[999999]"
              style={{ ...styles.popper, zIndex: 999999 }}
              {...attributes.popper}
            >
              <ul>
                {user.id === currentUserId || currentUserId === item.sessionOwnerUserId ? (
                  <li>
                    <button
                      onClick={() => navigate(`/order/${item.sessionEventId}/${item.userId}`)}
                      className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center space-x-2"
                    >
                      <Icon name="orders" size={18} color="#4b5563" />
                      <span>View Order</span>
                    </button>
                  </li>
                ) : null}
                <li>
                  <button
                    onClick={() => navigate(`/${user.username}`)}
                    className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center space-x-2"
                  >
                    <Icon name="person" size={18} color="#4b5563" />
                    <span>View Profile</span>
                  </button>
                </li>
              </ul>
            </div>,
            document.querySelector(".modal-box") || document.body,
          )}
      </div>
    </div>
  );
};

export default ParticipantCard;
