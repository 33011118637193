import { createPortal } from "react-dom";
import Icon from "../icon/Icon";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
  className?: string;
}

const Modal = ({ isOpen, onClose, children, title, className }: ModalProps) => {
  if (!isOpen) return null;

  return createPortal(
    <dialog
      id="modal"
      className={`modal modal-bottom z-[9999] md:modal-middle ${isOpen ? "modal-open" : ""}`}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div className={`modal-box relative max-h-[75%] w-full h-full flex flex-col bg-base-100 p-0 ${className}`}>
        <div className="z-50 w-full flex flex-row px-4 py-2 justify-between border-b-[1px] border-borderSecondary/30">
          <h2 className="text-xl font-bold text-textPrimary flex justify-center items-center">{title}</h2>

          <button className="btn btn-circle btn-ghost z-50 border-0" onClick={onClose}>
            <Icon name="close" />
          </button>
        </div>

        <div className="overflow-y-auto no-scrollbar p-4 h-full">{children}</div>
      </div>
      <form method="dialog" className="modal-backdrop fixed inset-0">
        <button onClick={onClose} className="cursor-default">
          close
        </button>
      </form>
    </dialog>,
    document.body,
  );
};

export default Modal;
