import { useCallback, useEffect, useRef } from "react";

interface InfiniteListProps<T> {
  data: T[];
  fetchNextPage: () => Promise<void>;
  fetchPreviousPage?: () => Promise<void>;
  hasNextPage: boolean;
  hasPreviousPage?: boolean;
  CardComponent: React.ComponentType<{ item: T; index: number; isLastItem: boolean }>;
  isFetching: boolean;
  direction?: "vertical" | "horizontal";
  className?: string;
  scrollToIndex?: number;
}

const InfiniteList = <T extends { id: string }>({
  data,
  fetchNextPage,
  fetchPreviousPage,
  hasNextPage,
  hasPreviousPage,
  isFetching,
  CardComponent,
  direction = "vertical",
  className = "",
  scrollToIndex,
}: InfiniteListProps<T>) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = useCallback(async () => {
    if (!containerRef.current || isFetching) return;

    const container = containerRef.current;
    const scrollHeight = container.scrollHeight - container.clientHeight;
    const isReversed = className.includes("flex-col-reverse");

    if (isReversed) {
      const offsetFromBottom = container.scrollHeight + container.scrollTop - container.clientHeight;
      const offsetFromTop = -container.scrollTop;

      if (offsetFromTop <= 50 && hasPreviousPage && fetchPreviousPage) {
        const currentScrollHeight = container.scrollHeight;

        await fetchPreviousPage();

        requestAnimationFrame(() => {
          if (container) {
            container.scrollTop = container.scrollHeight - currentScrollHeight + container.scrollTop;
          }
        });
      }

      if (offsetFromBottom <= 50 && hasNextPage) {
        await fetchNextPage();
      }
    } else {
      const scrollOffset = container.scrollTop;

      if (scrollOffset <= 50 && hasPreviousPage && fetchPreviousPage) {
        const offsetBeforeFetch = container.scrollHeight - scrollOffset;
        await fetchPreviousPage();
        requestAnimationFrame(() => {
          if (container) {
            container.scrollTop = container.scrollHeight - offsetBeforeFetch;
          }
        });
      }

      if (scrollOffset >= scrollHeight - 50 && hasNextPage) {
        await fetchNextPage();
      }
    }
  }, [fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, isFetching, className]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const debouncedScrollHandler = () => {
      if (isFetching) return;
      void handleScroll();
    };

    container.addEventListener("scroll", debouncedScrollHandler);

    return () => {
      container.removeEventListener("scroll", debouncedScrollHandler);
    };
  }, [handleScroll, isFetching]);

  useEffect(() => {
    if (scrollToIndex != null && scrollToIndex >= 0 && scrollToIndex < data.length && containerRef.current) {
      const element = containerRef.current.children[scrollToIndex] as HTMLDivElement;
      if (element) {
        element.scrollIntoView({ behavior: "instant", block: "center" });
      }
    }
  }, [scrollToIndex, data]);

  return (
    <div
      ref={containerRef}
      className={`flex no-scrollbar ${
        direction === "vertical" ? "flex-col space-y-2 overflow-y-auto" : "flex-row space-x-2 overflow-x-auto"
      } w-full ${className}`}
    >
      {data.map((item, index) => (
        <div key={item.id} className={direction === "vertical" ? "w-full" : "h-full"}>
          <CardComponent item={item} index={index} isLastItem={index === data.length - 1} />
        </div>
      ))}
      {isFetching && (
        <div className={`flex justify-center items-center mt-4 ${direction === "horizontal" ? "ml-4" : ""}`}>
          <span className="loading loading-spinner loading-md text-textSecondary"></span>
        </div>
      )}
    </div>
  );
};

export default InfiniteList;
