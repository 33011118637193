import { SessionEvent } from "@yoga-app/types";

interface SessionEventCheckResult {
  canPurchase: boolean;
  message: string | null;
  shortMessage: string | null;
}

/**
 * Check if a sessionEvent can be purchased, returning an object with details.
 */
export const checkIfSessionEventCanBePurchased = (sessionEvent?: SessionEvent): SessionEventCheckResult => {
  if (!sessionEvent) {
    return { canPurchase: false, message: "No session selected.", shortMessage: "Not Available" };
  }

  if (sessionEvent.maxParticipantCount && sessionEvent.participantCount >= sessionEvent.maxParticipantCount) {
    return {
      canPurchase: false,
      message: "This session is full and cannot accept more participants.",
      shortMessage: "Session Full",
    };
  }

  if (new Date(sessionEvent.endDateTime) < new Date()) {
    return {
      canPurchase: false,
      message: "This session has already ended.",
      shortMessage: "Session Ended",
    };
  }

  if (sessionEvent.cancelled) {
    return {
      canPurchase: false,
      message: "This session has been cancelled.",
      shortMessage: "Cancelled",
    };
  }

  return { canPurchase: true, message: null, shortMessage: null };
};
