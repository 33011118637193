import { useMutation } from "@tanstack/react-query";
import { PageOptions } from "@yoga-app/db-utils";
import { ContentItemUri } from "@yoga-app/types";
import config from "../config";
import { request } from "../utils/api";
import { trpc } from "../utils/trpc";
import { useAuth } from "./auth";

export const useGetContentItem = (contentItemUri: ContentItemUri, fieldsToInclude?: string[]) => {
  return trpc.content.getContentItem.useQuery(
    {
      contentItemUri: contentItemUri,
      fieldsToInclude,
    },
    {
      enabled: !!contentItemUri && !!contentItemUri.id && !!contentItemUri.type,
      staleTime: 600000, // 10 minutes
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  );
};

export const useGetContent = (userId: string, pageOptions: PageOptions) => {
  return trpc.content.getContentByUser.useInfiniteQuery(
    {
      userId: userId,
      limit: pageOptions.limit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 300000, // 5 minutes
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
    },
  );
};

export const useReportContent = () => {
  return trpc.content.reportContent.useMutation();
};

export const useGetReportedContentByUser = (pageOptions: PageOptions) => {
  return trpc.content.getReportedContentByUser.useInfiniteQuery(
    {
      limit: pageOptions.limit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 300000, // 5 minutes
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
    },
  );
};

export const useGetAllReportedContent = (pageOptions: PageOptions) => {
  return trpc.content.getAllReportedContent.useInfiniteQuery(
    {
      limit: pageOptions.limit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 300000, // 5 minutes
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
    },
  );
};

export const useApproveReportedContent = () => {
  const utils = trpc.useUtils();

  return trpc.content.approveReportedContent.useMutation({
    onSuccess: () => {
      void utils.content.getAllReportedContent.invalidate();
    },
  });
};

export const useDeleteContent = () => {
  const { getAccessToken } = useAuth();
  const utils = trpc.useUtils();

  return useMutation(
    async (contentItemUri: ContentItemUri) => {
      const response = await request<{ success: boolean }>(`${config.api.url}/content`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await getAccessToken()}`,
        },
        body: JSON.stringify({ contentItemUri }),
      });

      if (!response) {
        throw new Error("Failed to delete content");
      }

      return response;
    },
    {
      onSuccess: (_, contentItemUri) => {
        void utils.content.getContentByUser.invalidate();
        void utils.discovery.queryDiscoveryTimeline.invalidate();
        if (contentItemUri.type === "POST") {
          void utils.post.getPost.invalidate({ postId: contentItemUri.id }, { exact: false });
        }
        if (contentItemUri.type === "SESSION") {
          void utils.session.querySessionEventsBySessionId.invalidate({ sessionId: contentItemUri.id });
          void utils.session.getSession.invalidate({ sessionId: contentItemUri.id }, { exact: false });
        }
      },
    },
  );
};
